
/*
visibility: hidden;
 opacity: 0;
}*/

.js {
 visibility: visible;
 opacity: 1;
}



.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
	width: auto !important;
}


.margin-t-b-15 {
	margin-top: 15px !important;
	margin-bottom: 15px !important;
}

.width-75 {
	width: 75%;
}

.radio input[type="radio"], .radio-inline input[type="radio"] {

    margin-left: -999999px;
}
.custom-select-sm  {
    line-height: 28px;
	font-size: 13px;
}
.selectric .label{
	line-height: 28px;
	font-size: 14px;
}
.selectric-multiple .selectric-items li.selected:after{
	font-family: "FontAwesome";
	content: "\f00c";
	float: right;
	clear: both;
	color: #66d1b9;

}
.easy-autocomplete {width: 100% !important; }
.easy-autocomplete input{
	background-image: url("../../production/images/icon_search.svg");
	background-repeat: no-repeat;
	background-position: right 10px center;
}

.select2-container .select2-selection--multiple{

}
.select2-container--default .select2-selection--multiple,
.select2-container--default.select2-container--focus .select2-selection--multiple{

    border: 1px solid #ccc;
}
.select2-container .select2-selection--multiple .select2-selection__rendered{

}

.selection{
	line-height: 10px !important;
}

.select2-container .select2-search__field{
	    margin: 0;
    box-shadow: none;
    letter-spacing: normal;
    padding-left: 0 !important;
}
.select2-container .select2-search--inline .select2-search__field{
	margin-top: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice{
	padding: 5px;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #f0f0f0;
  color: #444; }





/* ---------------------------------------------------
    GENERAL ELEMENTS
----------------------------------------------------- */

body {
	font-family: 'Montserrat', sans-serif;
	background: #fafafa;
	-webkit-print-color-adjust: exact !important;
	print-color-adjust: exact !important;
}

/* Typography */

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: inherit !important;
    text-transform: none;
    color: #555;
    font-weight: 600;
	font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
}

p {
	font-size: 1.1em;
	font-weight: 300;
	line-height: 1.7em;
	color: #626262;
}
a.link {
	color: #65D1B9;
	font-weight: 600;
}
a.link i { color: #65D1B9;  }
.elogin-p {
	font-size: 1.2em;
	font-weight: 400;
	line-height: 1.5em;
	margin-bottom: 25px;
}

/* ---------------------------------------------------
    MISC
----------------------------------------------------- */

.text-teal{color: #66d1b9 !important;}

/* Hide Option for SL */
.custom-sl{display: none;}
.sl-content{display: none;}


/*mobile*/

.m-gene-links { margin: 0; }
.m-gene-links a{ color: #626262; }
.m-gene-links a:focus, .m-gene-links a:hover, .m-panel .panel-title a:hover,.m-panel .panel-title a:focus{text-decoration: none;}
.m-gene-links a:before{
	content: "\f0c1";
	font-family: "FontAwesome";
	color: #66d1b9;
	margin-right: 8px;
}
.m-panel .panel-title{
	font-weight: 600;
	text-transform: none;
	letter-spacing: normal;
	font-size: 13px;
	background: none;
	border: none;
	padding: 0px;
}
.m-panel{

	border-radius: 4px;
}
.m-panel > .panel {
    margin-bottom: 10px !important;
    border: 1px solid #ddd !important;
}
.m-panel  a[aria-expanded="true"]{
	background: none;
	color: #444444;
}
.m-panel .panel-body{
	padding: 20px;
}

.m-panel .panel-default > .panel-heading + .panel-collapse > .panel-body{

}


.m-gene-cat a:before{
	content: "\f0da";
	font-family: "FontAwesome";
	color: #66d1b9;
	margin-right: 8px;
}
.m-navbar{ min-height: 50px !important; }
.m-navbar .navbar-brand {
	height: 40px;
	line-height: 40px;
}
.m-section{padding: 10px 0;}


/* Remove Outline */

button:focus,
.btn-popover:active {
	outline: 0 !important;
}

.btn:focus,
input[type="radio"]:focus,
.btn-group .focus {
	outline: none !important;
	outline: 7px red !important;
}

/* Small Profile */
.query-dropdown{
	position: relative;
   	box-sizing: border-box;
    width: 150px;
}
.sm-profile {
	top: 55px;
	position: fixed;
	width: 100%;
	z-index: 9999;
}

/* Modal */

.genetic-testing-lab{
	padding-bottom: 0px;
}

.umatched_genes{
	padding: 0px;
	margin: 0px;
}
.unmatched_header{
	font-weight: bold;
	color: #d42215
}

.all_genes_matched{
	color: green
}
.modal-header {
    background-color: #fff;
    color: #6a358a;
    border-radius: 5px 5px 0 0;
}

.modal-header h4 {
	color: #ffffff;
}

/* Range Slider */

#ex1Slider .slider-selection {
	background: #65D1B9;
}

.slider-handle {
	background: #28B797;
}

.justify-right {
	display: flex !important;
	-webkit-box-pack: end !important;
	justify-content: flex-end !important;
}

section {
	padding: 120px 0;
}

.sidebar-collapsible .panel-heading {
	padding: 0;
}

.panel-group .panel {
	border: none;
}

.panel-group .panel + .panel {
	margin: 0;
}

.panel-group {
	margin: 0;
}

.float-right {
	margin: 0;
	text-align: right;
	float: right;
}

/* Center row */

.row-centered {
	text-align: center;
}

.col-centered {
	display: inline-block;
	float: none;
	/* reset the text-align */
	text-align: left;
	/* inline-block space fix */
	margin-right: -4px;
	text-align: center;
}

/* ---------------------------------------------------
    BUTTONS
----------------------------------------------------- */

.btn {
	font-weight: 600;
	text-transform: uppercase;
}

.btn:hover {
	transition-timing-function: ease-in-out;
	-moz-transition-timing-function: ease-in-out;
	-webkit-transition-timing-function: ease-in-out;
	-o-transition-timing-function: ease-in-out;
	transition-duration: .1s;
	-moz-transition-duration: .1s;
	-webkit-transition-duration: .1s;
	-o-transition-duration: .1s;
}

.btn-default {
	border-color: #ccc !important;
	color: #747474;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default:link,
.btn-default:visited {
	color: #747474;
}

/* Button: Remove inner shadow */

.btn:active,
.btn.active {
	box-shadow: none !important;
}

/* Button: Group */

.btn-group .btn {
	margin-right: 0;
}

/* Button: Size */

.btn-lg,
.btn-group-lg > .btn {
	padding: 16px !important;
	font-size: 14px !important;
	line-height: 1.5;
	border-radius: 3px;
}

.btn-xs,
.btn-group-xs > .btn {
	padding: 6px 10px !important;
	font-size: 11px;
	line-height: 1.5;
	border-radius: 3px;
}

.btn-xs i {
	margin-right: 0px !important;
}


/* Button: Gray Light */

.btn-gray-l {
	background-color: #e8e8e8;
	border-radius: 100px;
}

.btn-gray-l:hover {
	background-color: #d6d6d6;
	border-radius: 100px;
}

/* Button: Dark */

.btn-dark {
    font-size: 11px;
    letter-spacing: .5px;
    padding: 10px 13px;
    color: #fff;
    background: #6a358a;
    border: 2px solid #6a358a;
    border-radius: 100px;
}

.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active {
	color: #414661;
	background: transparent;
	border: 2px solid #414661;

}

.btn-dark i {
	color: #ffffff
}

.btn-dark:hover i {
	color: #414661
}

/* Button: Light */

.btn-light {
	font-size: 11px;
	letter-spacing: 0.5px;
	padding: 10px 13px;
	color: #747474;
	background: #E8E8E8;
	border: 2px solid #E8E8E8;
	border-radius: 100px;
}

.btn-light-advanced-filters {
	font-size: 11px;
	letter-spacing: 0.5px;
	padding: 10px 13px;
	color: #747474;
	background: #fff;
	border: 2px solid #E8E8E8;
	border-radius: 100px;
}

.btn-light:hover,
.btn-light:focus,
.btn-light:active {
	background: transparent;
	border: 2px solid #E8E8E8;
	color: #747474;
}



.btn-light--outline {
	font-size: 11px;
	letter-spacing: 0.5px;
	padding: 10px 13px;
	color: #bbb;
	background: #fff;
	border: 2px solid #bbb;
	border-radius: 100px;
}

.btn-light--outline:hover {
	color: #ffffff;
	background: #bbb;
}


.btn-light i {
	color: #747474
}

.btn-light:hover i {
	color: #747474
}

/* Button: Light Outline */

.btn-light-outline {
	font-size: 11px;
	letter-spacing: 0.5px;
	padding: 10px 13px;
	color: #747474;
	background: transparent;
	border: 2px solid #E8E8E8;
	border-radius: 100px;
}


.btn-light-outline2 {
	border: 2px solid #65D1B9 !important;
	color: #65D1B9 !important;
}



.btn-light-outline:hover,
.btn-light:focus,
.btn-light-outline:active,
.btn-light-outline:link,
.btn-light-outline:focus,
.btn-light-outline:visited {
	background: #E8E8E8;
	border: 2px solid #E8E8E8;
	color: #747474 !important;
}

.btn-ligh-outlinet i {
	color: #747474
}

.btn-light-outline:hover i {
	color: #747474
}


/* Button: Teal */

.btn-teal {
	font-size: 11px;
	letter-spacing: 0.5px;
	padding: 10px 13px;
	color: #ffffff;
	background: #65D1B9;
	border: 2px solid #65D1B9;
	border-radius: 100px;
}

.btn-teal-custom {
	border: 1px solid #65D1B9 !important;
	border-radius: 3px !important;
}

.updates-counter-teal {
	font-size: 13px;
	letter-spacing: 0px;
	padding: 0px 5px !important;
	color: #ffffff;
	background: #65D1B9;
	border: 2px solid #65D1B9;
	border-radius: 100%;
	margin: 0 5px !important;
}

.updates-counter-teal:hover {
	color: #fff;
}

.btn-teal--outline {
	font-size: 11px;
	letter-spacing: 0.5px;
	padding: 10px 13px;
	color: #65D1B9;
	background: #fff;
	border: 2px solid #65D1B9;
	border-radius: 100px;
}

.btn-teal--outline:hover {
	color: #ffffff;
	background: #65D1B9;
}


.btn-connect--teal {
	font-size: 12px;
	letter-spacing: 0.5px;
	padding: 5px 25px;
	color: #ffffff;
	background: #65D1B9;
	border: 2px solid #65D1B9;
	border-radius: 100px;
	margin: 10px 20px;
}

.btn-connect--teal:hover,
.btn-connect--teal:focus,
.btn-connect--teal:active {
	background: transparent;
	border: 2px solid #65D1B9;
	color: #65D1B9;
}

.btn-teal:hover,
.btn-teal:focus,
.btn-teal:active {
	background: transparent;
	border: 2px solid #65D1B9;
	color: #65D1B9;
}

.btn-teal > i {
	color: #ffffff;
}

.btn-teal:hover i, .btn-teal:focus i, .btn-teal:active i {
	color: #65D1B9;
}

/* Button: Purple */

.btn-purple {
    font-size: 11px;
    letter-spacing: .5px;
    padding: 10px 13px;
    color: #fff;
    background: #6a358a;
    border: 2px solid #6a358a;
    border-radius: 100px;
}

.btn-purple:hover,
.btn-purple:focus,
.btn-purple:active {
    background: transparent;
    border: 2px solid #6a358a;
    color: #6a358a
}

.btn-purple i {
	color: #ffffff
}

.btn-purple:hover i {
	color: #895491
}

/* Button: Purple */

.btn-red {
	font-size: 11px;
	letter-spacing: 0.5px;
	padding: 10px 13px;
	color: #ffffff;
	background: #d03e3e;
	border: 2px solid #d03e3e;
	border-radius: 100px;
}

.btn-red:hover,
.btn-red:focus,
.btn-red:active {
	background: transparent;
	border: 2px solid #d03e3e;
	color: #d03e3e;
}

.btn-red i {
	color: #ffffff
}

.btn-red:hover i {
	color: #d03e3e
}




/* ---------------------------------------------------
    GRIDS + TOOLS
----------------------------------------------------- */

/* No Gutter */

.no-gutter > [class*='col-'] {
	padding-right: 0;
	padding-left: 0;
}


/* Vertial Align */

.vcenter {
	display: flex;
	align-items: center;
}

/* Col Auto - Use when input form has a small label after it */

.col-auto {
	flex: 0 0 auto;
	width: auto;
	vertical-align: middle;
	max-width: none;
	margin-top: 5px;
	float: left;
}

.col-auto span {
	margin-left: -6px;
}


/* ---------------------------------------------------
    FORM CUSTOM STYLES + FIXES
----------------------------------------------------- */

/* Input Styles */

input,
textarea,
.select-items div,
.select-selected {
	border-radius: 4px !important;
	/* font-size: 11px; */
	/* requested by nick */
	font-size: 13px;
}

.form-horizontal .control-label {
	text-align: left;
	font-weight: 500;
}

.custom-label {
	margin-top: 5px;
}


label {
	font-weight: 500;
}

.input-date {
	line-height: 1.42857143 !important;
}

/* Fix height for date inputs */

/* Custom Radio Buttom Group */

.radio-group {
	display: inline-block;
}

.radio-group label {
	margin-bottom: 5px;
}

.radio-group:first-child label {
	padding-left: 0;
}

/* Fix spacing form */

.form-control {
	margin: 0;
	box-shadow: none;
	letter-spacing: normal;
}

/* Set New padding for form inputs */

input,
textarea,
select {
	padding: 8px !important;
}

input,
textarea,
select {
	text-transform: none;
}


.org_dial .intl-tel-input input, .org_dial .intl-tel-input textarea, .org_dial .intl-tel-input .select-items div, .org_dial .intl-tel-input .select-selected {
    border-radius: 4px !important;
    /* font-size: 11px; */
    font-size: 13px;
	visibility: hidden;
	background-color: #ffffff;
	box-shadow: 0 1px 2px 0 #c9ced1;
	}

.org_dial.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2 .selected-flag{
	background-color:#ffffff;
	background: #ffffff;
	border: solid;
	border-radius: 10px;
	border-width: 1.5px;
	border-color: #dcdcdc;
	background-color: white;

}
.org_dial .intl-tel-input.allow-dropdown.separate-dial-code .selected-flag,
.org_dial .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 .selected-flag,
.org_dial .intl-tel-input.allow-dropdown.separate-dial-code .selected-flag{

	background: #ffffff;
	border: solid;
	border-radius: 10px;
	border-width: 1.5px;
	border-color: #dcdcdc;
	background-color: white;
}
.form-group-title {
	font-weight: 600;
	font-size: 13px;
	margin-bottom: 10px;
}

.remove-row {
	display: block;
	margin-top: 10px;
	font-size: 1.5em;
}

.inputsmall {
	width: 80px;
}


/* ---------------------------------------------------
    NAV PILLS
----------------------------------------------------- */

/* Center Nav Pills */

.center-pills {
	display: inline-block;
}

.center-pills {
	display: flex;
	justify-content: flex-end;
}

/* Unstyled Nav Pills */
.read-zoom{
	background: none;
	border-radius: 0px !important;
	margin: 0px;
	padding-left: 12px;

}
.read-zoom > li.active > a{
	border-radius: 0px !important;
}

.read-zoom .dropdown-menu{
	padding: .7%;
}


.nav-pills-unstyled > li > a,
.nav-pills-unstyled > li > a:focus,
.nav-pills-unstyled > li > a:hover {
	background: none;
	border-radius: 0px !important;
}

.nav-pills-unstyled > li.active > a,
.nav-pills-unstyled > li.active > a:focus,
.nav-pills-unstyled > li.active > a:hover {
	background-color: none !important;
	border-radius: 0px !important;
}


.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
    color: #fff;
    background-color: #6a358a;
}

/* ---------------------------------------------------
    NAVBAR
----------------------------------------------------- */

/* v3*/

.navbar {
	/* min-height: 60px; */
	height: 50px;
	min-height:inherit;
  }

.navbar-brand {
	padding: 5px 15px;
	height: 60px;
	line-height: 60px;
}

.navbar-toggle {
	/* (80px - button height 34px) / 2 = 23px */
	/*margin-top: 23px; */
	margin-top: 12px;
	padding: 9px 10px !important;
}

.navbar-default .navbar-toggle{
	background: transparent;
	border-radius: 4px;
	border: 1px solid #666;
}

.navbar-default .navbar-toggle .icon-bar{
	background-color: #666;
}

@media (min-width: 768px) {
	.navbar-nav > li > a {
	  /* padding-top: 16.5px; */
	  padding-top: 13px;
	  padding-bottom: 16.5px;
	  line-height: 26.5px;
	}
  }

.navbar-nav > li > .dropdown-menu {
    background-color: #f3f3f3;
    padding: 0;
    top: 50px !important;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0 0 5px rgba(0,0,0,.1);
}

.navbar-nav > li > .dropdown-menu li a {
	color: #626262;
	display: block;
	position: relative;
	text-transform: uppercase;
	font-size: 11px;
	padding: 10px;
}

.navbar-nav > li > .dropdown-menu li a:hover {
	background-color: #e9e9e9;
}

.navbar-nav > li > .dropdown-menu li a i {
	color: #626262;
	float: left;
	font-size: 14px;
	margin: 0 10px 0 0;
	padding: 0;
	position: relative;
	right: 0;
	top: 0;
}

.navbar-nav > li > .dropdown-menu .divider {
	height: 1px;
	margin: 0px 0;
	overflow: hidden;
	padding: 0;
	background-color: #e5e5e526;
}

.navbar-default {
	/*border: none; */
}

.navbar-default .navbar-nav > li > a {
	/* color: #373737; */
	color: #fff;
	display: inline-block;
	font-size: 11px;
	font-weight: 600;
	margin-top: 1px;
	text-transform: uppercase;
	background: none;
}


.nav--center {
	display: flex;
	justify-content: center;
}





#top-search .search-form {
	top: 60px !important;
	background-color: #f3f3f3;
	border: 1px solid rgba(0,0,0,.15);
	border-radius: 4px;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	box-shadow: 0 0px 5px rgba(0,0,0,.175);
}


#top-search {
	padding-right: 10px;
	padding-bottom: 0px;

}

#top-search i {
	display: inline;
	margin: 0 8px 0 8px;
	font-size: 16px;
	color: #9a9a9a;
}

#top-search .search {
	padding-top: 15.5px;
	padding-bottom: 16px;
	line-height: 26.5px;
	position: relative;
	color: #373737;
	display: inline-block;
	font-size: 11px;
	font-weight: 600;
	margin-top: 1px;
	text-transform: uppercase;
}


.nav-avatar {
	margin-right: 10px;
}

.nav-home {
	display: inline !important;
	font-size: 16px;
}

/* ---------------------------------------------------
    MAIN - WRAP
----------------------------------------------------- */
.patient-details-emr p{
	padding-bottom: 0px;
	top: 35px;
	margin: 0px;
	z-index: 999;
	position: absolute;
	display: inline-block;
	font-size: 18px;

}

.emr-patient-search{
	text-align: center;
	padding-right: 90px;
	font-size: 16px;
}

.wrap {
	padding-top: 60px;
	padding-bottom: 0;
}

.patient-nav {
	background: #5D366D;
	position: fixed;
	width: 100%;
	z-index: 9999;
}

.patient-details {}

.patient-details p {
	font-size: 15px;
	padding: 11px 30px;
	color: #ffffff;
	margin: 0;
	display: inline-block;
}
.navbar-default .navbar-nav > li > a i {
	color: #fff;
  }


.patient-details p span {
    opacity: .8;
}

.patient-details p span.name {
	font-weight: 600;
	opacity: 1;
}

.patient-details p span.number {
	margin: 0 8px;
}

.patient-details p a.btn {
	margin: 0 0 0 15px;
	padding: 4px 10px;
}

.patient-details a.patient-details--update {
	margin: 0 0 0 15px;
	color: white;
	font-weight: 600;
	opacity: 100 !important;
	border-left: 1px solid rgba(255, 255, 255, 0.1);
	padding-left: 20px;
}

.patient-details a.patient-details--update:hover,
.patient-details a.patient-details--update:focus,
.patient-details a.patient-details--update:active {
	text-decoration: none;
    color: #6a358a;
}

.updates-- {
	border-left: none !important;
}

.patient-nav .nav-pills > li {
	margin: 0 60px 0 0;
	height: 45px;
	display: contents;
}

.patient-nav .nav-pills > li > a {
	font-weight: 600;
	text-transform: uppercase;
	font-size: 15px;
	color: #ffffff;
	vertical-align: middle;
	display: inline-block;
	padding-top: 15px;
	border-radius: 0px;
}

.patient-nav .nav-pills > li.active > a {
	color: #65D1B9 !important;
	padding-top: 15px;
}

.patient-nav .nav-pills > li.active > a,
.patient-nav .nav-pills > li.active > a:hover,
.patient-nav .nav-pills > li.active > a:focus {
	color: #fff;
	border-bottom: 4px solid #65D1B9;
}

.patient-nav .nav-pills > li > a,
.patient-nav .nav-pills > li > a:hover,
.patient-nav .nav-pills > li > a:focus {
	color: #fff;
	background: none;
}

.patient-nav .nav-pills > li > a:hover,
.patient-nav .nav-pills > li > a:focus {
	color: #65D1B9;
	background: none;
}



/* Tab Top */

li.nav-tabs--notifications.active a {
    font-weight: 600;
    font-size: 13px;
    color: #6a358a !important;
    text-transform: uppercase;
}
li.nav-tabs--notifications a:hover{order: 0;background: transparent;text-decoration: none; height: auto; color: #6a358a;border-color: transparent;}
.nav-tabs > li > a:hover {
  /*border-color: #eee #eee #ddd;
	background-color: #ddd;*/
	color: #6a358a !important;
	border-color: none !important;
}
li.nav-tabs--notifications a {
	font-weight: 600;
	font-size: 13px;
	color: #999 !important;
	text-transform: uppercase;
}

.panel-body--no-updates {
	font-size: 13px;
	line-height: 23px;
}

.btn--updates {
	text-align: right;
	margin-top: 10px;
}




/* ---------------------------------------------------
    SIDEBAR - Patient Details *05/15/2019*
----------------------------------------------------- */

#sidebar {
	width: 400px;
	position: absolute;
	right: -400px;
	height: 100%;
	z-index: 999;
	background: #fff;
	transition: all 0.3s;
  transition-property: all;
	overflow: auto;
	box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0px 0px 30px -17px rgba(0, 0, 0, 0.30);
	-moz-box-shadow: 0px 0px 30px -17px rgba(0, 0, 0, 0.30);
	box-shadow: 0px 0px 30px -17px rgba(0, 0, 0, 0.30);
}

#sidebar .form-group {
  margin-bottom: 5px;
}

#sidebar .dismiss {
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  background: none;
  border-radius: 0;
  color: #fff;
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
  transition: all .3s;
      transition-property: all;
  z-index: 99999;
  display: none;
  border: none;
  box-shadow: none;
}

#sidebar.active { right: 0px;} /*8/20/19*/

#dismiss {
	width: 35px;
	height: 35px;
	line-height: 35px;
	text-align: center;
	background: none;
	border-radius: 0;
	color: #ffffff;
	/* position: absolute; */
	position: absolute;
	/* top: 10px; */
	right: 10px;
	top: 15px;
	cursor: pointer;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
	z-index: 99999;
	display: none;
	border: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

#dismiss:hover {
	background: none;
	color: #aaa;
}

.dismiss {
	width: 35px;
	height: 35px;
	line-height: 35px;
	text-align: center;
	background: none;
	border-radius: 0;
	color: #ffffff;
	/* position: absolute; */
	position: absolute;
	/* top: 10px; */
	right: 10px;
	top: 15px;
	cursor: pointer;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
	z-index: 99999;
	display: none;
	border: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

.dismiss:hover {
	background: none;
	color: #aaa;
}

.overlay {
	display: none;
	position: fixed;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.7);
	z-index: 998;
	opacity: 0;
	transition: all 0.5s ease-in-out;
}
.overlay.active { display: block; opacity: 1; }
#sidebar .sidebar-header { padding: 25px 15px 10px 15px; background: #fff; }

#sidebar ul.components { padding: 20px 0; border-bottom: 1px solid #47748b;}



.flexbox {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: #f3f3f3;
  min-height: 900px;

}
.flexbox .col:nth-child(1) {

  flex-shrink: 1;
  width: 40%;
  background: #f3f3f3;
  background-size: cover;


}
.flexbox .col:nth-child(2) {
  background: #ffffff;
  width: 60%;


}
.notifs-wrap {
	width: 600px;
	position: fixed;
	margin-top: 157px;
	right: -600px;

	height: calc(100% - 150px);

	background-size: cover;
	z-index: 888;

	/*height: calc(var(--vh, 1vh) * 100);*/
	transition: all 0.3s;
	overflow-y: scroll;
	box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: -8px 0px 30px -17px rgba(0, 0, 0, 0.30);
	-moz-box-shadow: -8px 0px 30px -17px rgba(0, 0, 0, 0.30);
	box-shadow: -8px 0px 30px -17px rgba(0, 0, 0, 0.30);
	box-sizing: border-box;

}
.notifs-wrap.active { right: -15px;}
.notifs-wrap .wrap { padding: 20px 30px 20px 20px; background: #ffffff; }
.notifs-wrap .tabs{

	height: calc(100% - 150px);
	background-size: cover;
}
.notifs-wrap .tabs .nav li a {padding: 20px 20px;}

.notifs-global{
 	position: fixed;
	margin-top: 60px;
	right: -600px;

	height: calc(100% - 60px);

}



.notif-item {
	padding: 10px 0;
	margin-bottom: 10px;
	position: relative;
	border-bottom: 1px solid rgba(0,0,0,0.1);
}

.notif-item p{margin: 0; font-size: 13px;}
.notif-item .fa-trash{ font-size: 14px; }
.notif-item .del {position: absolute;right: 10px;}
.notif-item .date, .notif-item .time{ opacity: 0.7; font-size: 11px; }
.notif-item .title{ opacity: 0.7; font-size: 11px; }

.mCSB_inside>.mCSB_container { margin-right: 0; }
.mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail{width: 10px;border-radius: 0px;}
.mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ width: 8px; }
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail{ background-color: #000; background-color: rgba(0,0,0,0.1); -webkit-border-radius: 2px; -moz-border-radius: 2px; border-radius: 2px; }
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.45); -webkit-border-radius: 10px; -moz-border-radius: 10px; border-radius: 10px; }

/* Patient Data Tab Side Menu */
.patient-sidetab {background: #F1F1F1;/*margin-top: 45px;*/padding: 30px;height: calc(var(--vh, 1vh) * 100);}
.patient-sidetab .nav-pills > li {margin: 0;}
.patient-sidetab .nav-pills > li a {margin: 0;border: none;}
.patient-sidetab .nav-pills > li > a,
.patient-sidetab .nav-pills > li > a:hover,
.patient-sidetab .nav-pills > li > a:focus {font-weight: 600;font-size: 13px;color: #999999;text-transform: uppercase;}
.patient-sidetab .nav-pills > li.active > a {color: #414661;}
.patient-sidetab .nav-pills > li.active > a,
.patient-sidetab .nav-pills > li.active > a:hover,
.patient-sidetab .nav-pills > li.active > a:focus {color: #414661;background: none;}
.patient-sidetab .nav-pills > li > a:hover,
.patient-sidetab .nav-pills > li > a:focus {color: #414661;}
.patient-maintab {padding: 100px 40px;}


/* Patient Family History Sub Menu */
.patient-fam {
	padding: 52px 0 15px;
}

.patient-fam-content {
	padding: 15px 30px;
}

.patient-fam-pedigree {
	padding: 100px 15px 30px
}

.patient-fam ul.nav {
	background: #895491;
	position: relative;
}

.patient-fam ul.nav li:first-child {
	margin-left: 30px;
}

.patient-fam ul.nav li {
	height: 45px;
	display: table;
	margin-top: 0;
	margin-bottom: 0;
}

.patient-fam ul.nav li a {
	color: #6a358a;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 0;
	vertical-align: middle;
	display: table-cell;
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 0;
	padding-bottom: 3px;
	padding-top: 2px;
	background-color: transparent;
	background-color: initial;
}

.patient-fam ul.nav li a:hover,
.patient-fam ul.nav li a:focus {background: none;opacity: .8;}
.patient-fam ul.nav li.active a {background: none;}
.patient-fam ul.nav li a i {	color: #ffffff;}
.patient-fam ul.nav li.patient-fam-arrow { position: absolute; right: 0;}
.patient-fam ul.nav li.dropdown .dropdown-menu {
	padding: 10px 20px;
	background: #FFFFFF;
	border: 1px solid #CDCDCD;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.10);
	border-radius: 3px;
	min-width: 170px;
	margin: 0 !important;
}
.patient-fam ul.nav li.dropdown .dropdown-menu li:first-child {margin: 0;}
.patient-fam ul.nav li.dropdown .dropdown-menu li {height: 30px;}
.patient-fam ul.nav li.dropdown .dropdown-menu a {
	color: #626262 !important;
    font-size: 13px;
    text-transform: none;
    font-weight: normal;
}

.patient-fam a i.view-unchecked{ color: white; padding: 7px; }

#collapsethis.active {width: 100%;}


.flex-grid-sidebar {

  display: flex;
  justify-content: flex-end;
  /*margin: auto -1rem 1rem;*/
}

.col-right {
    height: 100vh;
}

.col-left {
  flex: 1;
}


 .col-right-hide{
 	display: none;
 	visibility: hidden;
 }



.patient-fam-tools {
	position: fixed;
	width: 100%;
	z-index: 9999;
}


/* Panel Notification */
.patient-notif {padding: 20px;}
.risk { padding: 30px;}
.risk-table table th {
	background: #33B2DF;
	color: #ffffff;
	border-right: 1px solid #ffffff;

}

.risk-table table tr td {
	font-size: 11px;
	border-top: none;
	border-right: 1px solid #ffffff;
}

.risk-table table {
	background: rgba(51, 1178, 223, 0.10);
	margin-bottom: 30px;
}

.risk-table table .col-gray--head {
	background: #95A5A6;
	color: #ffffff;
}

.risk-table table .col-gray {
	background: #F2F3F4;
}

.graph-border {
	padding: 20px;
	border: 1px solid #dddddd;
	border-radius: 10px;
	margin-top: 20px;
	background-color: #fff;
}

/* ---------------------------------------------------
    SECTIONS
----------------------------------------------------- */

.section {
	padding: 60px 0;
}

.section-header {
	margin-bottom: 10px;
	padding: 5px 0px 12px;
	border-bottom: 1px solid #ccc;
}

.section-header h2 {
    text-transform: uppercase;
    font-weight: 600;
    margin: 15px 0;
    color: #6a358a !important;
    letter-spacing: -.5px;
}

.section-sub-header {
	margin-top: 40px;
	margin-bottom: 20px;
	padding: 20px 0 10px;
	border-bottom: 1px solid #ccc;
}

.section-sub-header h3 {
    text-transform: uppercase;
    margin: 15px 0 15p;
    color: #6a358a;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -.2px;
}


.patient-details a.patient-details--update:active, .patient-details a.patient-details--update:focus, .patient-details a.patient-details--update:hover {
    text-decoration: none;
    color: #6a358a;
}


.section-header .btn {
	margin: 0;
}

.section-footer .btn {
	float: right;
	margin: 30px 15px 0;
}


/* ---------------------------------------------------
    CONTENT - CHART
----------------------------------------------------- */

#content {
	background: #ffffff;
	width: calc(100% - 250px);
	padding: 20px 0 40px 0;
	transition: all 0.3s;
	position: relative;
	top: 75px;
	right: 0;
	height: calc(100vh - 110px);
	overflow: scroll;
}

#content.active {
	width: 100%;
}

.chart-area {
	z-index: 0;
}

/* Zoom Controls

.zoom-controls {
	background: #ffffff;
	border: 1px solid #ddd;
	display: block;
	position: fixed;
	left: 0;
	bottom: 40px;
	height: 130px;
	width: auto;
	max-width: 320px;
	padding: 10px;
	text-align: center;
	margin: 30px 20px 20px;
	z-index: 2;
	-webkit-box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);
	-moz-box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);
	box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);
	border-radius: 4px;
}
#zoom-slider .slider-selection {
	background: #28B797;
}

.zoom-controls .slider {
	background: #ffffff;
	position: relative;
}

.slider-handle {
	background: #65D1B9;
}

*/

.zoom-controls{
	background: #ffffff;
 	text-align: center;
	display: block;
}

.zoom-controls a {
	display: inline-block;
}

.zoom-controls a i{
	color: #c8c8c8 !important;
}

.zoom-controls .zoom{
	margin: 5px 0 10px 0;
}

.zoom-controls .zoom .fa-search-plus,
.zoom-controls .zoom .fa-search-minus{
	display: inline-block;
}

.zoom-controls .btn {
	margin-right: 0px;
	margin-top: 5px;
	margin-bottom: 5px;
	padding: 4px 6px;
	font-size: 9px;

}

.zoom-controls .checkbox-inline {
	font-size: 11px !important;
	line-height: 16px;
}

.zoom-controls .checkbox-inline input{
	margin-top: 1px;
}

/* Pedigree Chart - Icons + Modal */

.pedigree-action {}

.pedigree-action a {
	background: #7C7D7E;
	color: #ffffff;
	border-radius: 20px;
	width: 36px;
	height: 36px;
	display: table;
	text-align: center;
	margin: 10px 0;
}

.pedigree-action a:focus,
.pedigree-action a:active {
	text-decoration: none;
}

.pedigree-action a i {
	color: #ffffff;
	display: table-cell;
	vertical-align: middle;
}

.pedigree-action a:hover {
	background: #65D1B9;
	text-decoration: none;
}



.gene-disease-container{
	display: flex;
	justify-content: space-between;
}
/* Form Header */
@media (max-width: 1000px){
	.gene-disease-container{
		flex-direction: column;

	}
}

.form-header {
	border-bottom: 1px solid rgba(0,0,0,.05);
	margin-bottom: 10px;
	padding-bottom: 10px;

}

.form-header h3.title {
	margin: 0 0;
	color: #6a358a;
}

.form-header .btn {
	margin: 0;
	text-align: right;
	float: right;
}

.form-header-2 {
	padding-bottom: 10px;
	/* margin-bottom: 10px; */
}

.form-header-2 h3.title {
	margin: 0 0;
	font-size: 13px;
}

.form-header-2 .btn {
	margin: 0;
	text-align: right;
	float: right;
}

#paper_canvas {
    float: right;
    border: none;
}

/* ---------------------------------------------------
    SEARCH
----------------------------------------------------- */

.home-search {
	background-image: url("../../production/images/pattern_bg.png");
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-position: center;
}

.btn-home-search {
	padding: 0 8px;
}

.btn-home-search a {
	background-color: #fff;
	color: #414661 !important;
	letter-spacing: .8px;
	border-radius: 10px;
	border: 2px solid #dcdcdc !important;
	padding: 30px 25px;
	margin: 0 8px 20px;
	display: block;
	min-height: 170px;
	text-align: center;
}

.btn-home-search a:hover {
	background: #EFEFEF;
}

.btn-home-search img {
	margin: 20px 0;
}

.btn-home-search span {
	display: block;
	text-transform: none;
	font-weight: 400;
	white-space: normal;
}


.input-home-search {
	border-radius: 100px !important;
	border: 2px solid #dcdcdc;
	font-size: 20px !important;
	text-transform: none !important;
	padding: 20px 30px !important;
}

.input-home-search:focus {
	border: 2px solid #65D1B9 !important;
	outline: 0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}


/* Results */

.results {
	display: block;
	padding: 25px 20px 25px 25px;
	margin: 0 5px 5px 0;

}

.results-male {
	background-color: #1B9CFC;
	border-radius: 15px;
	border: 1px solid #EAEAEA;



}

.results-female {
	background-color: #FD7272;
	border-radius: 15px;
	border: 1px solid #EAEAEA;


}

.results-name {
	margin: 0 !important;
	color: white;
	line-height: 1;
	font-weight: 600;
}

.results-age {
	margin: 0 !important;
	font-size: 12px;
	line-height: 2.5;
	color: white;
}

.results-date {
	margin: 0 !important;
	line-height: 1;
	font-size: 10px;
	color: white;
	opacity: 0.6;
}

/**/

.widget {
	background-color: #f1f1f1;
}

.widget-body {
	padding: 25px 20px 15px 25px;
}

.widget-body .category-list {
	margin-bottom: 20px;
}

.widget-head {
	background-color: #414661;
	color: white;
	padding: 10px;
	margin: 0 !important;
}

.widget-head h5 {
	text-align: center;
	color: white;
}


/* ---------------------------------------------------
    SIDEBAR
----------------------------------------------------- */

.sidebar {
	width: 250px;
	position: fixed;

	right: 0;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	z-index: 0;
	background: #7386D5;
	color: #fff;
	transition: all 0.3s;
}

.sidebar.active {
	margin-left: -250px;
}

.sidebar .sidebar-header {
	padding: 20px;
	background: #1B9CFC;
}

.sidebar .sidebar-header p {
	color: #ffffff;
}

.sidebar ul.CTAs {
	padding: 20px !important;
}

.sidebar ul.components {
	padding: 20px 0;
	border-bottom: 1px solid #47748b;
}

.sidebar ul p {
	color: #fff;
	padding: 10px;
}

.sidebar ul li a {
	padding: 10px;
	font-size: 1.1em;
	display: block;
}

.sidebar ul li a:hover {
	color: #7386D5;
	background: #fff;
}

.sidebar ul li.active > a,
a[aria-expanded="true"] {
	color: #fff;
	background: #6d7fcc;
}

.sidebar-pin {
	position: relative;
}

.sidebar-pin img {
	display: block;
	position: absolute;
	left: -12px;
	top: -0px;
}

.sidebar-icons {
	margin-top: 20px;
	display: block;
	text-align: center;
	padding: 30px 0s;
}

.sidebar-icons ul {
	list-style: none;
	display: inline-block;
	text-align: center;
	margin: 0;
}

.sidebar-icons ul li {
	display: inline-block;
}

.sidebar-icons ul li:hover {
	background: none !important;
}

/* .sidebar-icons ul li a{ padding: 0;}*/

.sidebar-icons ul li a:hover {
	opacity: .5;
	background: none !important;
}

/* Sidebar Second Profile */

.sidebar-2 {
	position: fixed;
	right: 250px !important;
}

.sidebar-2 {
	background: #FEB8B8 !important;
}

.sidebar-2 .sidebar-header {
	background: #FD7272 !important;
}

.sidebar-1 {
	background: #65BDFF !important;
}

.square {
	height: 75px;
	width: 75px;
	background-color: #d7d7d7;
	display: inline-block;
	border-radius: 0% !important;
}

.square-selected {
	border: 4px solid #65d0b8 !important;
	box-shadow: 0 2px 4px 0px rgba(101, 208, 184, 0.5);
}

.circle {
	height: 75px;
	width: 75px;
	background-color: #d7d7d7;
	border-radius: 50% !important;
	display: inline-block;
}

.circle-selected {
	border-radius: 50% !important;
	border: 4px solid #65d0b8 !important;
	box-shadow: 0 2px 4px 0px rgba(101, 208, 184, 0.5);
}

.radio-custom {
	display: inline-block !important;
	margin-right: 10px !important;
	margin-top: 0px !important;
}

.no-margin-top-c {
	margin-top: 0px !important;
}

.popover {
	width: 190px !important;
	border: 1px solid rgb(214, 214, 214) !important;
	z-index: 99 !important;
}

.popover-content {
	max-width: 190px !important;
	/* padding: 8px 2px !important; */
}


.btn-popover {
	color: #626262;
}

.btn-popover:hover {
	color: #414661;
}

.switch-wrap {
	float: left;
	margin-right: 15px;
}


/* Results Sidebar */

.category-list li a:hover,
.category-list li a:focus,
.category-list li a:active {
	background: #e8e8e8;
	color: #626262;
}


/* Family History Sidebar */

.sidebar-collapsible-wrap {
	max-width: 300px;
	/* width: 400px; */
	/* width: inherit; */
	background: #f3f3f3;

	height: 100%;
	/*position: fixed*/
	;
	/*position: absolute;
     right: 0; */
}

.sidebar-collapsible .panel {
	background: #f3f3f3;
	max-width: 100%;
	width: 100%;
	padding: 52px 0 30px;
}

.sidebar-collapsible .panel-heading {
	padding-bottom: 0;
}

.sidebar-collapsible .panel-heading a,
.sidebar-collapsible .panel-heading a:focus,
.sidebar-collapsible .panel-heading a:visited {
	outline: 0;
	text-decoration: none;
}

.sidebar-collapsible .panel-heading .panel-title {
	background: #895491;
	color: #ffffff;
	font-weight: 700;
	letter-spacing: 2px;
	border-bottom: 1px solid rgba(255, 255, 255, .1);
	border-top: 1px solid #895491;
}

.sidebar-collapsible .panel-heading .panel-title i {
	color: #ffffff;
}

.sidebar-collapsible hr {
	border-color: #e2e2e2;
}

/* Criteria */

.criteria {
	margin-bottom: 10px;
}

.criteria h4 {
	font-size: 13px;
	text-transform: none;
}

.criteria ul {
	list-style: outside disc;
	margin: 0px 0 0 25px;
	padding-bottom: 10px;
}

.criteria ul li {
	font-size: 12px;
	margin: 0;
	line-height: 20px;
}

.criteria span {
	color: #cc3434;
}

/* Panel Summary */

.panel-summary {
	background: #e9e9e9;
	padding: 10px;
	margin-bottom: 10px;
	position: relative;
}

.panel-summary h4 {
	font-size: 13px;
	text-transform: none;
}

.panel-summary p {
	font-size: 12px;
	margin: 0;
	max-width: 90%;
	text-wrap: pretty;
}

.panel-summary-edit {
	position: absolute;
	right: 25px;
}

.panel-summary-delete {
	position: absolute;
	right: 10px;
}

.panel-summary-edit a i {
	color: red;
}

.panel-col .panel-summary{
	width: 100%;
    float: left;
    display: inline-block;
    margin-right: 10px;
}

.panel-col-gene .panel-summary{
	width: 100%;
    float: left;
    display: inline-block;
    margin-right: 10px;
}

/* Panel Forms */

.panel-form {
	background: #efefef;
	padding: 25px;
}

.panel-form p {
	font-size: 12px;
	margin: 0;
}

.panel-form h4 {
	font-size: 13px;
	text-transform: none;
}

.panel-form-2 {
	border-radius: 4px;
	display: block;
	background: #efefef;
	padding: 20px 20px 10px 25px;
}

.panel-form-2 p {
	font-size: 12px;
	margin: 0;
}

.panel-form-2 h4 {
	font-size: 13px;
	text-transform: none;
}

.panel-form-3 {
	background: #efefef;
	padding: 10px 20px;
	border: 1px #ddd solid;
}

.panel-form-3 p {
	font-size: 12px;
	margin: 0;
}

.panel-form-3 h4 {
	font-size: 13px;
	text-transform: none;
}

.panel-form-dark {
	background: #e9e9e9;
	padding: 25px;
}

.panel-form-dark p {
	font-size: 12px;
	margin: 0;
}

.panel-form-dark h4 {
	font-size: 13px;
	text-transform: none;
}

/* ---------------------------------------------------
    DISEASES
----------------------------------------------------- */

.disease-wrap {
	padding: 10px 0;
}

.disease--form {
	top: -5px;
	position: relative;
}

.disease--form input {
	margin-bottom: 10px;
}

.disease--icon {
	margin: 0 0 25px;
	text-align: right;
}

.disease--icon .disease--icon-left {
	display: inline-block;
	margin-right: 15px;
}

.disease--icon .disease--icon-right {
	display: inline-block;
}


/* ---------------------------------------------------
    SYMBOLS
----------------------------------------------------- */

.symbol {
	float: right;
}

.symbol-color {
	width: 35px;
	height: 35px;
	background: #999999;
	display: block;
}

.symbol-bw {
	width: 35px;
	height: 35px;
	background: #999999;
	display: block;
}

.symbol-bw-1 {
	background: url("../../production/images/symbols/bw-1.png");
}

.symbol-bw-2 {
	background: url("../../production/images/symbols/bw-2.png");
}

.symbol-bw-3 {
	background: url("../../production/images/symbols/bw-3.png");
}

.symbol-bw-4 {
	background: url("../../production/images/symbols/bw-4.png");
}

.symbol-bw-5 {
	background: url("../../production/images/symbols/bw-5.png");
}

.symbol-bw-6 {
	background: url("../../production/images/symbols/bw-6.png");
}

.symbol-bw-7 {
	background: url("../../production/images/symbols/bw-7.png");
}

.symbol-bw-8 {
	background: url("../../production/images/symbols/bw-8.png");
}

.symbol-bw-9 {
	background: url("../../production/images/symbols/bw-9.png");
}

.symbol-bw-10 {
	background: url("../../production/images/symbols/bw-10.png");
}


.symbol-color-1 {
	background: #f44336 !important;
}

.symbol-color-2 {
	background: #E91E63 !important;
}

.symbol-color-3 {
	background: #9C27B0 !important;
}

.symbol-color-4 {
	background: #3F51B5 !important;
}

.symbol-color-5 {
	background: #03A9F4 !important;
}

.symbol-color-6 {
	background: #009688 !important;
}

.symbol-color-7 {
	background: #CDDC39 !important;
}

.symbol-color-8 {
	background: #FFC107 !important;
}

.symbol-color-9 {
	background: #FF9800 !important;
}

.symbol-color-10 {
	background: #795548 !important;
}


.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover,
.btn-group > .btn:focus,
.btn-group-vertical > .btn:focus,
.btn-group > .btn:active,
.btn-group-vertical > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn.active {
	background: #E8E8E8;
	color: #747474;
}



.btn-group2 > .btn:hover,
.btn-group-vertical > .btn:hover,
.btn-group2 > .btn:focus,
.btn-group-vertical > .btn:focus,
.btn-group2 > .btn:active,
.btn-group-vertical > .btn:active,
.btn-group2 > .btn.active,
.btn-group-vertical > .btn.active {
	background: #65d1b9 !important;
	color: #fff !important;
	border: 2px solid #65d1b9 !important;
}

.legend {
	border: 1px solid #e9e9e9;
	padding: 10px;
	width: max-content;
}

.legend li {
	display: block;
	vertical-align: middle;
	margin-bottom: 10px;
}

.legend li span {
	width: 25px;
	height: 25px;
	background: #999999;
	display: inline-block;
	margin-right: 8px;
	vertical-align: middle;
}


/* ---------------------------------------------------
    TABLE
----------------------------------------------------- */

table.dataTable.row-border tbody th,
table.dataTable.row-border tbody td,
table.dataTable.display tbody th,
table.dataTable.display tbody td {
   border-color: #e8e8e8;
   padding: 8px;
}

table.dataTable tbody tr {
	background-color: transparent;
}

.table-custom a.link {
	color: #65D1B9;
	font-weight: 600;
}

.table-custom .table-action li {
	display: inline-block;
	margin-right: 4px;
}

.table-custom .table-action li a {
	font-size: 11px;
	letter-spacing: 2px;
	min-width: 38px;
	height: 38px;
	color: #747474;
	background: #E8E8E8;
	text-align: center;
	border: 1px solid #E8E8E8;
	border-radius: 4px;
}

.table-custom .table-indent {
	margin-left: 20px;
}

.table-custom .table-indent-2 {
	margin-left: 40px;
}

.table-custom .table-indent-3 {
	margin-left: 60px;
}

.table-custom .table-indent-4 {
	margin-left: 80px;
}

.table-custom .table-indent-5 {
	margin-left: 100px;
}

.table-custom a {
	color: #65D1B9;
	font-weight: 600;
}

.table-custom .table-action a:hover,
.table-custom .table-action a:focus {
	background: #DFDFDF;
}

.table-custom .table-action .btn {
	padding: 3px !important;
}

.table-custom .table-btn-edit,
.table-custom .table-btn-add,
.table-custom .table-btn-view {
	border-radius: 100px;
	padding: 3px !important;
	margin: 0;
	min-width: 76px;
}

table.dataTable thead th,
table.dataTable thead td {
   padding: 8px;
   border-bottom: 1px solid #bfc0c7;
   line-height: 15px;
   vertical-align: bottom;
}

table.dataTable.stripe tbody tr.odd,
table.dataTable.display tbody tr.odd {
	background: #ffffff;
}

.table-custom .indent-border {
	padding-left: 15px;
}

.table-custom tbody tr td  {
	vertical-align: middle;
 }

/* Table Filter */

.query-save .form-control {
	width: 240px;
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}

.query-filter h5 {
	margin: 10px 0;
}

.query-filter button {
	margin-right: 5px;
}

.table-filter {
	margin-bottom: 10px;
}

.table-filter .form-control {
	height: 30px;
	font-size: 13px;
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}

.table-filter .form-group {
	margin: 0px 10px;
}

.table-filter .form-search {
	width: 187px;
}

.table-filter .btn {
	height: 30px;
	margin-bottom: 5px;
	padding: 6px 8px !important;
	border-radius: 50px;

}

.table-filter .btn-default {
	font-size: 11px;
	color: #747474;
}

.table-footer .pagination {
	margin-top: 0;
	width: auto;
	float: right;
}

.filters-dropdown-unchecked {
	color: white; padding: 20px;
}

.popover-custom {
	font-family: 'Montserrat', sans-serif !important;
	font-size: 13px;
}

.popover-custom .form-control, .popover-custom .selectric .label {
	/*height: 30px !important; */
	font-size: 13px !important;
	line-height: 28px;
}

.popover-custom .popover-title .popover-x .close {
	line-height: 15px;
}

.popover-custom .popover-title {
	font-size: 11px;
	padding: 8px 8px;
}


.popover-wide {
	max-width: 770px !important;
}

.popover-medium {
	max-width: 500px !important;
}


#colPop {
	width: 260px !important;
}

#colPop .popover-content {
	max-width: 260px !important;
}

#colPop .popover-content .btn {
	margin-right: 5px;
}

#colPop .popover-content ul li {
	margin-bottom: 5px;
}


#cancerPop {
	width: 770px !important;
}

#cancerPop .popover-content {
	max-width: 770px !important;
}

#genePop {
	width: 770px !important;
}

#genePop .popover-content {
	max-width: 770px !important;
}

#genePop .select-selected:after {
	top: 12px;
}

#advanced-filters {
	width: 1150px !important;
}

#advanced-filters .popover-content {
	max-width: 1150px !important;
}


#refPop {
	width: 500px !important;
}

#refPop .popover-content {
	max-width: 500px !important;
}

#refPop {
	width: 500px !important;
}

#refPop .popover-content {
	max-width: 500px !important;
}


#morePop {
	width: 500px !important;
}

#morePop .popover-content {
	max-width: 500px !important;
}

#morePop {
	width: 500px !important;
}

#morePop .popover-content {
	max-width: 500px !important;
}



/* Fixed header scroll table */
.sticky-header{
	top: 60px !important;
	background: #fafafa;
}

/* ---------------------------------------------------
    MODALS
----------------------------------------------------- */

.modal{
	z-index: 999999;
}

.modal .btn-upload {
	height: 36px;
	border-radius: 4px;
	padding: 8px 16px;
}

.modal-confirm {
	text-align: center;
}

.modal-confirm h3 {
	margin-bottom: 20px;
	line-height: auto;
}

.modal-confirm .btn {
	width: 70px;
	margin-top: 0;
	margin-bottom: 0;
}

.modal-body {
	padding: 30px;
}

.modal-header .modal-title {
    color: #6a358a;
    text-transform: capitalize;
}
.btn-dark:active, .btn-dark:focus, .btn-dark:hover {
    color: #6a358a;
    background: transparent;
    border: 2px solid #6a358a;
}

.modal-footer .btn + .btn {
	margin-top: 0;
}

   /*new addition*/
   .modal-footer .btn {
	padding: 10px 25px;
   margin-left: 5px;
   margin-right: 5px;
}

.modal-confirm .btn {
   width: 70px;
   margin-top: 0;
   margin-bottom: 0;
   margin-left: 10px;
   margin-right: 10px;
}

.modal .btn-upload {
   height: 36px;
   border-radius: 100px;
   padding: 8px 16px;
}


/* ---------------------------------------------------
    SETTINGS
----------------------------------------------------- */

.settings .nav-pills > li.active {
	margin-left: 0;
	padding-left: 0;
}

.settings .nav-pills > li {
	margin-left: 0;
	margin-top: 0;
	margin-bottom: 0;
}

.settings .nav-pills > li a {
	padding: 10px;
}

.settings .nav-pills > li:hover a {
	border-radius: 0;
}

.settings .payment {
	border: 1px solid #c1c1c1;
	padding: 30px 30px 40px 30px;
}


/* ---------------------------------------------------
    FOOTER
----------------------------------------------------- */

.footer-custom {
	background: #414661;
	position: fixed;
	bottom: 0;
	width: 100%;
	color: #ffffff;
	padding: 7px;
}

.footer-custom a {
	color: #ffffff;
	margin-left: 20px;
}



/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */


@media (min-width: 992px){
.modal-xl {
    width: 1150px;
}
.text-right-lg{
	text-align: right ;
}
.navbar-brand {
	padding: 0 30px;
	height: 60px;
	line-height: 60px;
}


}

@media (max-width: 1024px) {
	.flex-grid-sidebar {
		display: block;
	}

	.flex-grid-sidebar .col {
		width: 100%;
	}

	.sidebar-collapsible-wrap {
		margin: 0 auto;
	}


}

@media (max-width: 991px) {
	.sidebar {
		top: 150px;
	}

	.padding-patient-content {
		padding: 5% 5%;
	}

	.mobilecenter {
		text-align: center !important;
	}

	.patient-details {
		position: relative;
		text-align: center;
		border-bottom: 1px solid rgba(255, 255, 255, .1);
	}

	.patient-sidetab {
		height: auto;
		padding: 20px;
		/* margin-top: 80px; */
	}

	.sidebar-collapsible,
	.patient-fam {
		padding: 0;
	}

	.sidebar-collapsible-wrap {
		margin: 0 auto;
	}

	.patient-nav {
		position: static;
	}

	.results {
		display: inline-block;
	}

	#top-search {
		display: none;
	}



}

@media (max-width: 768px) {
	.sidebar-1 {
		display: none;
	}

	.sidebar-2 {
		display: none;
	}

	.sidebar-1.active {
		margin-right: 0;
	}

	#content.active {
		width: calc(100% - 250px);
	}

	.sidebarCollapse span {
		display: none;
	}

	.togglesidebarbuttonhide {
		display: none;
	}

	.footer-custom {
		display: none;
	}

	#content {
		width: 100%;
		height: 100vh;
		top: 0px;
		padding-top: 150px;
	}

	.zoom-controls {
		top: 150px;
	}

	.disease--icon {
		text-align: left;
	}

	.disease-wrap {
		margin: 30px 0 20px;
	}

	.patient-details {
		position: relative;
	}

	.wrap {
		padding-top: 50px;
		padding-bottom: 0;
	}

	.patient-nav {
		margin-top: 10px;
	}

	.patient-nav .nav-pills > li {
		margin: 0 40px 0 20px;
	}

	.patient-nav .nav-pills > li > a {
		font-size: 14px;
	}

	.patient-sidetab {
		height: auto;
		padding: 15px;
		/*margin-top: 110px; */
	}

	.patient-sidetab .nav-pills > li a,
	.patient-sidetab .nav-pills > li a:hover,
	.patient-sidetab .nav-pills > li a:focus {
		font-size: 12px;
	}

	.patient-maintab {
		padding: 30px 30px;
	}

	.patient-maintab .title {
		margin-right: -15px;
		margin-left: -15px;
	 }

	.no-padding-left {
		padding-left: 15px !important;
	}

	.btn-upload {
		display: block;
		margin-top: 15px !important;
	}

	.settings {
		margin-top: 0;
	}

	.settings .nav {
		margin-bottom: 30px;
	}

	.sidebar-collapsible {
		padding: 0;
	}

	.sidebar-collapsible-wrap {
		margin: 0 auto;
	}

	.radio-group {
		display: block;
	}

	.radio-group label {
		padding-left: 0;
	}

	.footer-custom a {
		text-align: center;
		margin-left: 0;
	}

	.home-search {
		margin-top: 40px;
	}

	.signout {
		border-top: 1px solid rgba(255, 255, 255, .1);
		margin-top: 0;
		padding-top: 0;
	}

	.patient-fam ul.nav li {
		margin-right: 8px;
	}

	.patient-fam ul.nav li:first-child {
		margin-left: 8px;
	}

	.patient-fam ul.nav li.dropdown .dropdown-menu {
		position: absolute;
	}

	.navbar-nav .open .dropdown-menu {
		background: rgba(27, 22, 28, 0.95);
	}

	.navbar-nav .open .dropdown-menu li a i {
		color: #626262;
		float: left;
		font-size: 14px;
		margin: 0 10px 0 0;
		padding: 0;
		position: relative;
		right: 0;
		top: 10px;
	}

	.navbar-default .navbar-nav > .open > a,
	.navbar-default .navbar-nav > .open > a:hover,
	.navbar-default .navbar-nav > .open > a:focus {
		background: transparent;
	}

	.navbar-nav .open li > .dropdown-menu li a {
		color: #626262;
		line-height: 24px;
		height: 30px;
	}

	.center-pills {
		/* display: flex; */
		/* justify-content: flex-start; */
		text-align: center;
		margin: 0 auto;
		display: block;
	}


section {
	padding: 70px 0;
}
}

@media (min-width: 768px) {
	.patient-sidetab {
		margin-top: 45px;
	}

	.form-horizontal .control-label {
		padding-top: 5px;
	}

	.nav-settings {
		right: -70px;
	}

	.modal-dialog-wide {
		width: 750px !important;
		margin: 30px auto;
	}

	.no-gutter-lg > [class*='col-'] {
		padding-right: 0;
		padding-left: 0;
	}

	.patient-details--update {
		border-left: none;
		padding-left: 0;
	}
	.panel-col .panel-summary{
		float: left;
	}
	.panel-col-gene .panel-summary{
		float: left;
	}
}

@media (max-height: 900px) {
	.sidebar {
		min-height: 100%;
		overflow-y: scroll !important;
		overflow-x: hidden !important;
	}
}

@media (max-height: 320px) {
	.patient-sidetab {
		margin-top: 0;
	}
}

.custom-disease-upload-and-saml-configs {
	display: flex;
	justify-content: space-between;
	gap: 20px; 
	margin: 20px;
	flex-wrap: wrap; 
  }
  
  .custom-disease-upload-container {
	height: 40%;
	margin: 2px auto;
	padding: 20px;
	border: 1px solid #ccc;
	border-radius: 12px;
	background-color: #f9f9f9;
	flex: 1;
	min-width: 40%; 
	box-sizing: border-box; 
  }
  .saml-configs-container,
  .oauth-configs-container {
	margin: 2px auto;
	padding: 20px;
	border: 1px solid #ccc;
	border-radius: 12px;
	background-color: #f9f9f9;
	flex: 1;
	min-width: 45%; 
	box-sizing: border-box;
  }
  
  /* Row for Side-by-Side Layout */
  .custom-disease-upload-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
  }
  
 
  .custom-disease-upload-col {
	flex: 1;
	margin-right: 10px;
	max-width: 100%; 
  }
  
  
  .custom-disease-upload-col-submit {
	flex: 0;
	width: 100%;
	margin-top: 0px;
  }
  

  .custom-disease-upload-control-fileupload {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
  }
  
  .custom-disease-upload-file-label {
	color: #6a0dad;
	font-size: 13px;
	margin-bottom: 8px;
	cursor: pointer;
  }

  
  .custom-disease-upload-submit-btn {
	align-content: flex-end;
	background-color: #6a0dad;
	color: white;
	font-size: 11px;
	padding: 10px 20px;
	border-radius: 8px;
	border: none;
	cursor: pointer;
  }
  
  .custom-disease-upload-submit-btn:hover {
	background-color: #5a0a8b;
  }
  
  /* SAML Configs Section */
  .saml-configs-container {
	margin: 2px auto;
	padding: 20px;
	border: 1px solid #ccc;
	border-radius: 12px;
	background-color: #f9f9f9;
	flex: 1;
	min-width: 45%; 
	box-sizing: border-box;
  }
  
  .saml-configs-input,
  .oauth-configs-input {
	margin: 8px 0;
	padding: 8px;
	font-size: 1rem;
	width: 100%; 
	border-radius: 8px;
	border: 1px solid #6a0dad;
  }
  
  .saml-configs-checkbox,
  .oauth-configs-checkbox {
	margin-left: 8px;
  }
  
  .saml-configs-control-fileupload {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
  }
  
  .saml-configs-file-label {
	color: grey;
	font-size: 1rem;
	margin-bottom: 8px;
	cursor: pointer;
  }
  
  
.custom-disease-upload-file-input,
.saml-configs-file-input {
	position: relative !important;
	padding: 8px;
	font-size: 12px;
	opacity: unset !important;
	border-radius: 8px;
	color: #6a0dad;
	cursor: pointer;
	width: 100%; 
}

.custom-disease-upload-file-input:focus,
.saml-configs-file-input:focus {
	opacity: unset !important;
	position: relative !important;
  	border-color: #5a0a8b; 
  	outline: none; 
}


.question-customization-add-questions{
	
}